import { defineStore } from 'pinia'

interface CourseModalStore {
  slug: string
  isOpen: boolean
  targetType: string
  targetId: string
}

export const useCourseModalStore = defineStore('courseModal', {
  state: () => {
    return {
      slug: '',
      isOpen: false,
      targetType: '',
      targetId: '',
    } as CourseModalStore
  },
  actions: {
    openModal(slug: string, targetType: string, targetId: string) {
      this.slug = slug
      this.isOpen = true
      this.targetType = targetType
      this.targetId = targetId

      const { $segment } = useNuxtApp()
      $segment.track('Modal Opened', {
        modal_id: slug,
      }, {
        integrations: {
          'Facebook Pixel': false,
        },
      })
    },
    closeModal() {
      this.slug = ''
      this.targetType = ''
      this.targetId = ''
      this.isOpen = false
    },
  },
})
